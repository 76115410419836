import intlMessagesEN from './i18n/localization/en.json';
import intlMessagesVi from './i18n/localization/vi.json';
import initReducer from './Store/initReducer';

export default {
  name: "QuestionAndAnswer",
  dir: "QuestionAndAnswer",
  pathRoot: "question-and-answer",
  routes: [
    {
      url: "detail-question/:code",
      component: "Page/DetailQuestion",
      layout: "VerticalLayout",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Chi tiết câu hỏi | Hita Fun",
        titleI18n: "QuestionAndAnswer:titleDetailQuestion",
        headerStyle: "fill",
        exact: true,
      },
    },
    {
      url: "",
      component: "Page",
      layout: "VerticalLayout",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Hỏi đáp | Hita Fun",
        titleI18n: "QuestionAndAnswer:title",
        headerStyle: "fill",
        exact: true,
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  redux: initReducer,
  isAuthenticate: "Any",
};
