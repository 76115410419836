import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";
import initReducer from "./Store/initReducer";

export default {
  name: "InfomationManagement",
  dir: "InfomationManagement",
  pathRoot: "infomation-management",
  routes: [
    {
      url: "",
      component: "Page/index",
      layout: "VerticalLayout",
      meta: {
        authRoute: false,
      },
      props: {
        title: "Hita Fun - Đối tác Tâm Trí Lực",
        titleI18n: "InfomationManagement:title",
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: "Any",
  redux: initReducer,
  roles: ["superadmin", "view_admin", "edit_admin"],
};
