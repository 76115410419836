import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";
import initReducer from "./Store/initReducer";
import initSagas from "./Store/initSagas";

export default {
  name: "Support",
  dir: "Support",
  pathRoot: "support",
  routes: [
    {
      url: "faq",
      component: "Page/FAQ",
      layout: "VerticalLayout",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Câu hỏi thường gặp | Hita Fun",
        titleI18n: "MENU:question",
        headerStyle: "fill",
        exact: true,
      },
    },
    {
      url: "rules",
      component: "Page/Rules",
      layout: "VerticalLayout",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Câu hỏi thường gặp | Hita Fun",
        titleI18n: "MENU:rules",
        headerStyle: "fill",
        exact: true,
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: true,
  redux: initReducer,
  sagas: initSagas,
  isAuthenticate: "Any",
};
