import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";
export default {
  name: "Home",
  dir: "Home",
  pathRoot: "",
  routes: [
    {
      url: "content-detail/:id",
      component: "Page/ContentDetailPage",
      layout: "VerticalLayout",
      meta: {
        authRoute: false,
      },
      props: {
        title: "Trang chủ | Hita Fun",
        titleI18n: "Home:title",
        headerStyle: "fill",
        exact: true,
      },
    },
    {
      url: "",
      component: "Page/Homepage",
      layout: "VerticalLayout",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Trang chủ | Hita Fun",
        titleI18n: "Home:title",
        headerStyle: "fill",
        exact: true,
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: "Any",
};
